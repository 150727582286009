export class OfflineError extends Error {
}
export class UserNotLoggedInError extends Error {
}
export class AppDoesNotExistError extends Error {
}
export class RecordMutationDoesNotExistError extends Error {
}
export class NoLocalUrlError extends Error {
}
export class GraphQLErrors extends Error {
    constructor(messages) {
        super();
        this.messages = messages;
    }
}
export class FetchError extends Error {
    constructor(status, statusText) {
        super(statusText);
        this.status = status;
        this.statusText = statusText;
    }
}
